.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form {
  width: 100%;
}

.qrContainer, .qrLoading {
  height: 280px;
}

.qrContainer {
  opacity: 1;
  transform: scale(1);
  transition: transform 300ms cubic-bezier(0.34, 1.56, 0.64, 1), opacity 300ms;
  max-height: 280px;
  max-width: 280px;
  margin: auto;

  &.pre-animate {
    opacity: 0.5;
    transform: scale(0.8);
  }

  canvas {
    padding: 0.625rem;
    border-radius: var(--border-radius-default);
    // background: var(--color-white);
    margin-top: -0.625rem;
    height: 100%;
    width: 100%;
  }
}

.qrForm {
  h3 {
    margin: 1.5rem 0 1rem 0;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
  }

  ol {
    list-style: none;
    counter-reset: item;
    padding: 0 1.75rem;

    li {
      counter-increment: item;
      text-align: left;
      margin: 0.75rem 0;
      display: flex;

      &::before {
        content: counter(item);
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 1.375rem;
        height: 1.375rem;
        padding: 0;
        margin: 0 0.75rem 0 0;
        background: var(--color-primary);
        border-radius: 50%;
        font-size: smaller;
        color: black;
        :global(html.theme-myihcLight) & {
          color: white;
        }
        :global(html.theme-myihcDark) & {
          color: white;
        }
      }
    }
  }
}
