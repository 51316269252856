.container {
  color: #000000;
  background-color: #F4BB2E;
  :global(html.theme-myihcLight) & {
    background: linear-gradient(180deg, #9FBAF1, #3D568F);
    color: white;
  }
  :global(html.theme-myihcDark) & {
    background: linear-gradient(180deg, #9FBAF1, #3D568F);
    color: white;
  }
}

.logoContainer {
  display: flex;
  align-items: center;
}

.logoTitle {
  font-size: 24px;
  font-weight: 700;
  margin-left: 8px;
}

.downloadButtonsWrapper {
  display: flex;
}

.downloadButton {
  margin: 0 8px;

  & > img {
    max-width: 100%;
  }
}

.title {
  font-size: 48px;
  font-weight: 700;
}

.subtitle {
  font-size: 20px;
  font-weight: 500;
}
